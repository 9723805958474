import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

declare var $;
@Component({
  selector: 'app-rateproveedor',
  templateUrl: './rateproveedor.page.html',
  styleUrls: ['./rateproveedor.page.scss'],
})
export class RateproveedorPage implements OnInit {
  cal:any;
  comentario:any;

  nombre:any;
  rating:any;
  image:any;

  constructor(public router: Router, private modalController:ModalController) { } 

  ngOnInit() {
  }

  ionViewWillEnter(){
    $.getJSON(localStorage.url + "?action=get_proveedor_data",{
      order_id: localStorage.orderid,
    },(res)=>{
      this.nombre = res[0].nombre;
      this.rating = res[0].rating;
      this.image = res[0].pp;
     })
  }

  sendRequest(){
    this.cal = $("#calif").val();
    $.get(localStorage.url + "?action=califica",{
      cali:this.cal,
      order_id:localStorage.orderid,
      comment:this.comentario
    },(res)=>{
       console.log("ROUTER")
          this.router.navigate(['/trabajo-realizado']);
    })
  }

}
