import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
declare var $
declare var cordova;
@Component({
  selector: 'app-masdatos',
  templateUrl: './masdatos.page.html',
  styleUrls: ['./masdatos.page.scss'],
})
export class MasdatosPage implements OnInit {
  penales:any;
  policiacos:any;
  dni:any;
  constructor(public router: Router,private modalController:ModalController) { }

  ngOnInit() {
  }

  gotoback(){
      // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,

    }); 
  }

  zoom(i){
    
    var url = $("#" + i).attr("src");
    //alert(url);
    var ref = cordova.InAppBrowser.open(url, '_blank','location=yes,hideurlbar=yes,toolbarcolor=black,navigationbuttoncolor=white,closebuttoncolor=white')
    
  }

  ionViewWillEnter(){
    $.getJSON(localStorage.url,{
      action:"get_prov_data",
      correo:localStorage.providprev
    },(r)=>{
      this.penales = r[0].imagen_antecedentes_penales;
      this.policiacos = r[0].imagen_antecedentes;
      this.dni = r[0].dni
    })
    
  }

}
