import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
declare var $
@Component({
  selector: 'app-modaladdr',
  templateUrl: './modaladdr.page.html',
  styleUrls: ['./modaladdr.page.scss'],
})
export class ModaladdrPage implements OnInit {

  constructor(private modalController:ModalController) { }
  address:any;
  address_id:any;
  moreaddress:any = [];

  
  ngOnInit() {
  }

  ionViewWillEnter() {
    $.getJSON(localStorage.url,{
      action:"get_saved_addr",
      correo:localStorage.email
    },(r)=>{
      this.moreaddress = r;
      console.log(r);
    })
  }
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
      'address':this.address
    }); 
  }

  select(id,name){
    this.address = name;
    this.address_id = id;
    this.modalController.dismiss({
      'dismissed': true,
      'address':this.address,
      'address_id': this.address_id 
    });
  }

}
