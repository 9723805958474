import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModaladdrPageRoutingModule } from './modaladdr-routing.module';

import { ModaladdrPage } from './modaladdr.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModaladdrPageRoutingModule
  ],
  declarations: [ModaladdrPage]
})
export class ModaladdrPageModule {}
