import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'servicios',
    loadChildren: () => import('./servicios/servicios.module').then( m => m.ServiciosPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  }, 
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'email',
    loadChildren: () => import('./email/email.module').then( m => m.EmailPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'code',
    loadChildren: () => import('./code/code.module').then( m => m.CodePageModule)
  },
  {
    path: 'suplidor',
    loadChildren: () => import('./suplidor/suplidor.module').then( m => m.SuplidorPageModule)
  },
  {
    path: 'pendientes',
    loadChildren: () => import('./pendientes/pendientes.module').then( m => m.PendientesPageModule)
  },
  {
    path: 'detalle',
    loadChildren: () => import('./detalle/detalle.module').then( m => m.DetallePageModule)
  },
  {
    path: 'suplidorperfil',
    loadChildren: () => import('./suplidorperfil/suplidorperfil.module').then( m => m.SuplidorperfilPageModule)
  },
  {
    path: 'visorcliente',
    loadChildren: () => import('./visorcliente/visorcliente.module').then( m => m.VisorclientePageModule)
  },
  {
    path: 'global',
    loadChildren: () => import('./global/global.module').then( m => m.GlobalPageModule)
  },
  {
    path: 'logintype',
    loadChildren: () => import('./logintype/logintype.module').then( m => m.LogintypePageModule)
  },
  {
    path: 'suplidormapa',
    loadChildren: () => import('./suplidormapa/suplidormapa.module').then( m => m.SuplidormapaPageModule)
  },
  {
    path: 'mapsuplidor',
    loadChildren: () => import('./mapsuplidor/mapsuplidor.module').then( m => m.MapsuplidorPageModule)
  },
  {
    path: 'usermapa',
    loadChildren: () => import('./usermapa/usermapa.module').then( m => m.UsermapaPageModule)
  },
  {
    path: 'suplidor2',
    loadChildren: () => import('./suplidor2/suplidor2.module').then( m => m.Suplidor2PageModule)
  },
  {
    path: 'proveedorservicios',
    loadChildren: () => import('./proveedorservicios/proveedorservicios.module').then( m => m.ProveedorserviciosPageModule)
  },
  {
    path: 'modaladdr',
    loadChildren: () => import('./modaladdr/modaladdr.module').then( m => m.ModaladdrPageModule)
  },
  {
    path: 'select',
    loadChildren: () => import('./select/select.module').then( m => m.SelectPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'sendpay',
    loadChildren: () => import('./sendpay/sendpay.module').then( m => m.SendpayPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'balance',
    loadChildren: () => import('./balance/balance.module').then( m => m.BalancePageModule)
  },
  {
    path: 'sendpayadelanto',
    loadChildren: () => import('./sendpayadelanto/sendpayadelanto.module').then( m => m.SendpayadelantoPageModule)
  },
  {
    path: 'balancefinal',
    loadChildren: () => import('./balancefinal/balancefinal.module').then( m => m.BalancefinalPageModule)
  },
  {
    path: 'prov-rate-modal',
    loadChildren: () => import('./prov-rate-modal/prov-rate-modal.module').then( m => m.ProvRateModalPageModule)
  },
  {
    path: 'rateproveedor',
    loadChildren: () => import('./rateproveedor/rateproveedor.module').then( m => m.RateproveedorPageModule)
  },
  {
    path: 'datosproveedor',
    loadChildren: () => import('./datosproveedor/datosproveedor.module').then( m => m.DatosproveedorPageModule)
  },
  {
    path: 'pagosproveedor',
    loadChildren: () => import('./pagosproveedor/pagosproveedor.module').then( m => m.PagosproveedorPageModule)
  },
  {
    path: 'tarjeta',
    loadChildren: () => import('./tarjeta/tarjeta.module').then( m => m.TarjetaPageModule)
  },
  {
    path: 'bancos',
    loadChildren: () => import('./bancos/bancos.module').then( m => m.BancosPageModule)
  },
  {
    path: 'masdatos',
    loadChildren: () => import('./masdatos/masdatos.module').then( m => m.MasdatosPageModule)
  },
  {
    path: 'loginweb',
    loadChildren: () => import('./loginweb/loginweb.module').then( m => m.LoginwebPageModule)
  },  {
    path: 'extrafinanciamiento',
    loadChildren: () => import('./extrafinanciamiento/extrafinanciamiento.module').then( m => m.ExtrafinanciamientoPageModule)
  },
  {
    path: 'sendfactura',
    loadChildren: () => import('./sendfactura/sendfactura.module').then( m => m.SendfacturaPageModule)
  },
  {
    path: 'trabajo-realizado',
    loadChildren: () => import('./trabajo-realizado/trabajo-realizado.module').then( m => m.TrabajoRealizadoPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
